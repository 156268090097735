import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 2,
    risk: 5,
    lpSymbol: 'CHEWY-BONE LP',
    lpAddresses: {
      109: '0x324EEf33AF720cE44DEAB7e32F4367a82b4eA43b',
      97: '',
    },
    tokenSymbol: 'CHEWY',
    tokenAddresses: {
      109: '0x2761723006d3Eb0d90B19B75654DbE543dcd974f',
      97: '',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 16,
    risk: 5,
    lpSymbol: 'CFUN-BONE LP',
    lpAddresses: {
      109: '0x3175E4412648d12d40DcA7028D37B19B57a4b048',
      97: '',
    },
    tokenSymbol: 'CFUN',
    tokenAddresses: {
      109: '0x8cC82045E761329FA13C9b0A0a31d76615fEc109',
      97: '',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 14,
    risk: 5,
    lpSymbol: 'SKULLZ-BONE LP',
    lpAddresses: {
      109: '0xFB7cC7bD5Bca846B1B7a9077365F0513585c4B91',
      97: '',
    },
    tokenSymbol: 'Skullz',
    tokenAddresses: {
      109: '0x5212B42ef96A47Af93F3a6c801227b650EDEb12f',
      97: '',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 15,
    risk: 5,
    lpSymbol: 'SPUNK-BONE LP',
    lpAddresses: {
      109: '0x9Bd71c133F8FBeC65B16053ED9b5b44930416E6C',
      97: '',
    },
    tokenSymbol: 'SPUNK',
    tokenAddresses: {
      109: '0xd1509c2Aa2411b993dE76d65523F8Af96F86c943',
      97: '',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 11,
    risk: 5,
    lpSymbol: 'LEASH-BONE LP',
    lpAddresses: {
      109: '0x47922b03239ea5E300aFF970B99cD65674e06DD4',
      97: '',
    },
    tokenSymbol: 'LEASH',
    tokenAddresses: {
      109: '0x65218A41Fb92637254B4f8c97448d3dF343A3064',
      97: '',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 13,
    risk: 5,
    lpSymbol: 'Hoichi-BONE LP',
    lpAddresses: {
      109: '0x5667D9F8DdEfF0bb584aC996706f40A11F044720',
      97: '',
    },
    tokenSymbol: 'Hoichi',
    tokenAddresses: {
      109: '0xfe668A3d6f05e7799AAE04659fC274AC00d094c0',
      97: '',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 1,
    risk: 5,
    decimals: 6,
    lpSymbol: 'CHEWY-USDT LP',
    lpAddresses: {
      109: '0x0E9674cff160A1aBCD57141BC8fD2E2598D99689',
      97: '',
    },
    tokenSymbol: 'USDT',
    tokenAddresses: {
      109: '0xaB082b8ad96c7f47ED70ED971Ce2116469954cFB',
      97: '',
    },
    quoteTokenSymbol: QuoteToken.CAKE,
    quoteTokenAdresses: contracts.cake,
  },
  {
    pid: 10,
    risk: 5,
    lpSymbol: 'LEASH-CHEWY LP',
    lpAddresses: {
      109: '0x8888C702B7695Cc06B7F0c3f98A6C2a9a32C87D5',
      97: '',
    },
    tokenSymbol: 'LEASH',
    tokenAddresses: {
      109: '0x65218A41Fb92637254B4f8c97448d3dF343A3064',
      97: '',
    },
    quoteTokenSymbol: QuoteToken.CAKE,
    quoteTokenAdresses: contracts.cake,
  },
  {
    pid: 0,
    risk: 5,
    hide: true,
    lpSymbol: 'USDT-BONE LP',
    lpAddresses: {
      109: '0xB69A4132af7495aE809Ce0155b7be1889ef72918',
      97: '',
    },
    tokenSymbol: 'BONE',
    tokenAddresses: {
      109: '0xC76F4c819D820369Fb2d7C1531aB3Bb18e6fE8d8',
      97: '',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 8,
    risk: 5,
    lpSymbol: 'SHIB-BONE LP',
    lpAddresses: {
      109: '0x368616Ce3500a9ecE8708eB45d3a351990B8407a',
      97: '',
    },
    tokenSymbol: 'SHIB',
    tokenAddresses: {
      109: '0x495eea66B0f8b636D441dC6a98d8F5C3D455C4c0',
      97: '',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 3,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'CHEWY',
    lpAddresses: {
      97: '',
      109: '0x324EEf33AF720cE44DEAB7e32F4367a82b4eA43b', // Chewy Single Token Staking
    },
    tokenSymbol: 'CHEWY',
    tokenAddresses: {
      97: '',
      109: '0x2761723006d3Eb0d90B19B75654DbE543dcd974f',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 17,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'CFUN',
    lpAddresses: {
      97: '',
      109: '0x3175E4412648d12d40DcA7028D37B19B57a4b048', // CFUN Single Token Staking
    },
    tokenSymbol: 'CFUN',
    tokenAddresses: {
      97: '',
      109: '0x8cC82045E761329FA13C9b0A0a31d76615fEc109',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 12,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'Bonewhale',
    lpAddresses: {
      97: '',
      109: '0x8C68216B96cd89a38b693DDf566C59214CE52898', // Bonewhale Single Token Staking
    },
    tokenSymbol: 'Bonewhale',
    tokenAddresses: {
      97: '',
      109: '0x33cA1D9305D5bB1cbeba0440C1B0fD5e1f8C81B9',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 4,
    risk: 5,
    decimals: 9,
    isTokenOnly: true,
    lpSymbol: 'PooShi',
    lpAddresses: {
      97: '',
      109: '0x715F605613bA572C3322f458dfafd34E8A26aEfD', // PooShi Single Token Staking
    },
    tokenSymbol: 'PooShi',
    tokenAddresses: {
      97: '',
      109: '0x5Bc161445f32170653eB61bdeCf80c9D7F5C345a',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 5,
    risk: 5,
    lpSymbol: 'EWW-BONE LP',
    lpAddresses: {
      109: '0x4BB6443A1B9120C5B5AF367eD7a5D1dEFB40911c',
      97: '',
    },
    tokenSymbol: 'EWW',
    tokenAddresses: {
      109: '0x9D3E821ff67Cc010313E6CDBA159d5b6305e9431',
      97: '',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 6,
    risk: 5,
    lpSymbol: 'BONESHI-BONE LP',
    lpAddresses: {
      109: '0x9d310AE7a9E1359f0DBfC8a8D33dA3feFF34861B',
      97: '',
    },
    tokenSymbol: 'Boneshi',
    tokenAddresses: {
      109: '0xd348D4f38f9f6710ae18580B161024D3C115F512',
      97: '',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 7,
    risk: 5,
    lpSymbol: 'WETH-BONE LP',
    lpAddresses: {
      109: '0xfC9EDB2A8F82aCA827ea0d51c916B7238196D90c',
      97: '',
    },
    tokenSymbol: 'WETH',
    tokenAddresses: {
      109: '0x8ed7d143Ef452316Ab1123d28Ab302dC3b80d3ce',
      97: '',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 9,
    risk: 5,
    decimals: 9,
    lpSymbol: 'POOSHI-CHEWY LP',
    lpAddresses: {
      109: '0x4C9f05218d9e99655C157CEe9e3341EF9FC56288',
      97: '',
    },
    tokenSymbol: 'POOSHI',
    tokenAddresses: {
      109: '0x5Bc161445f32170653eB61bdeCf80c9D7F5C345a',
      97: '',
    },
    quoteTokenSymbol: QuoteToken.CAKE,
    quoteTokenAdresses: contracts.cake,
  },
]

export default farms
